<template>
  <v-app>
<div>
<BreadcrumbComponent :items=items></BreadcrumbComponent>


<v-row justify="center">
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ form_title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Nom Entreprise *"
                  required
                  v-model=account.name
                ></v-text-field>
                
              </v-col>
              <v-col cols="12" sm=6 md=6>
                <v-text-field
                  label="Téléphone *"
                  required
                  v-model=account.phone
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm=6 md=6>
                <v-text-field
                  label="E-mail *"
                  required
                  v-model=account.email
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm=12 md=12>
                <v-text-field
                  label="Adresse"
                  required 
                  v-model=account.address
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Nom du Contact"
                  required
                  v-model=account.contact_full_name
                ></v-text-field>
                
              </v-col>
              <v-col cols="12" sm=6 md=6>
                <v-text-field
                  label="Téléphone Contact"
                  required
                  v-model=account.contact_phone
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm=6 md=6>
                <v-text-field
                  label="E-mail du Contact"
                  required
                  v-model=account.contact_email
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm=6 md=6>
                <v-text-field
                  label="Numéro d'Identification"
                  required
                  v-model=account.contact_id
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm=6 md=6>
                <v-select
                  :items="id_types"
                  item-value=name
                  item-text=name
                  label="Type d'Identification *"
                  required
                  v-model=account.contact_id_type
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Prefix (Sous-domaine) *"
                  required
                  v-model=account.prefix
                ></v-text-field>
                
              </v-col>
              <v-col cols="12" sm=6 md=6>
                <v-select
                  :items="rates"
                  item-value=id
                  item-text=name
                  label="Devise de Vente *"
                  required
                  v-model=account.rate_id
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            class="close_modal_button"
            text
            @click="close"
          >
            Fermer
          </v-btn>
          <v-btn
            color="blue"
            text
            class = "confirm_modal_button"
            v-on:click=save
            :loading="loading"
            :disabled="loading"
            @click="loader = 'loading'"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
<PreloaderComponent :enable=enable></PreloaderComponent>

<v-container fluid class="settingscontainer" v-if=enable>
  <v-card
  elevation="2" class="text-right"
>
  <v-card>
  <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Rechercher"
        single-line
        hide-details
        class="tablesearch"
      ></v-text-field>
    </v-card-title>
  <v-data-table
    :headers="headers"
    :items="rows"
    :items-per-page="10"
    class="elevation-1"
    :search="search"
  >
  <template v-slot:item.id="{ item }">
        {{ getAccountNumber(item.id) }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2 edit_button"
        @click="edit(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        class="mr-2 view_button"
        @click="view(item)"
      >
        mdi-eye-outline
      </v-icon>
    </template>
  </v-data-table>
</v-card>

</v-card>
</v-container>
</div>
</v-app>
</template>

<script>
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'
import PreloaderComponent from '../components/PreloaderComponent.vue'

export default {
  name: 'AccountsView',
  methods: {
    load: function(){
      var self = this;
      this.$axios.get('/businesses?orderasc=name')
      .then(function (response) {
        self.rows = response.data
        self.enable = true          
      })
    },
    validateData: function(){

      if(!/^[a-z0-9]*$/.test(this.account.prefix)){
        alert("Le préfix ne peut contenir que des chiffres et des lettres minuscules")
        return false
      }
      if(this.account.name == ""){
        alert("Vous devez un nom pour le compte")
        return false  
      }

      if(this.account.address == ""){
        alert("Vous devez indiquer une adresse")
        return false
      }

      if(this.account.prefix == ""){
        alert("Vous devez choisir un prefix. Seuls les lettres et les chiffres sont autorisés.")
        return false
      }

      if(this.account.phone == ""){
        alert("Vous devez choisir un téléphone")
        return false
      }

      if(this.account.email == ""){
        alert("Vous devez choisir un email")
        return false
      }

      if(this.account.contact_full_name == ""){
        alert("Vous devez indiquer un nom pour la personne de contact")
        return false
      }

      if(this.account.contact_phone == ""){
        alert("Vous devez indiquer un téléphone pour la personne de contact")
        return false
      }

      if(this.account.contact_email == ""){
        alert("Vous devez indiquer un email pour la personne de contact")
        return false
      }

      if(this.account.contact_id == ""){
        alert("Vous devez indiquer un numéro d'identité pour la personne de contact")
        return false
      }
      return true;
    },
    getAccountNumber: function(id){
        var renewal_number = 1000 + parseInt(id)
        return renewal_number
      },
      save: function(){
        var self = this;
        
        var url = ''
        if(this.validateData()){
          this.loading = true
          if (this.index > -1) { 
            url = '/businesses/'+this.account.id
            this.$axios.put(url+"?data="+encodeURIComponent(JSON.stringify(self.account)))
            .then(response => {
              Object.assign(self.rows[self.index], response.data)
              self.dialog = false
              self.loading = false
              self.reset()
            })
          }else{
            url = '/businesses'
            this.$axios.post(url, self.account)
            .then(response => {
              self.rows.push(response.data.business);
              self.dialog = false
              self.loading = false
              self.reset()
            })
          }
        }
        
      },
      edit: function(item){
        this.index = this.rows.indexOf(item)
        this.account = Object.assign({}, item)
        this.form_title = "Editer Compte : "+this.account.name
        this.dialog = true
      },
      close: function(){
        this.dialog = false
        this.reset()
        this.loading = false
      },
      view: function(item){
        this.$router.push('/accounts/'+item.id)
      },
      reset: function(){
        this.account = {
          name: '',
          rate_id:1, 
          prefix:'',
          address: '', 
          phone: '', 
          email: '', 
          contact_full_name: '', 
          contact_phone: '', 
          contact_email: '',
          contact_id:'',
          contact_id_type:"CIN"
        }
        this.index = -1
        this.form_title = 'Nouveau Compte'
      }
  },
  mounted(){
    this.load()
  },
  data: () => ({
      search: '',
      index: -1,
      loading: false,
      form_title: "Nouveau Compte",
      rates: [{name:'HTG', id:1}, {name:'USD', id:2}],
      id_types: [{name:'CIN'}, {name:'NIF'}, {name:'PASSPORT_NUMBER'}, {name:'DRIVER_LICENSE'}],
      items: [
        {
          text: 'Comptes',
          disabled: false,
          href: '/accounts',
        }
      ],
      fluid: true,
      enable: false,
      headers: [
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'NOM', value: 'name' },
          { text: 'TELEPHONE', value: 'phone' },
          { text: 'NOM CONTACT', value: 'contact_full_name' },
          { text: 'TELEPHONE CONTACT', value: 'contact_phone' },
          { text: 'SOUS-DOMAINE', value: 'prefix' },
          { text: 'ACTIONS', value: 'actions' },
        ],
        rows: [],
        roles: [],
        dialog:'',
        account: {
          name: '',
          rate_id:1, 
          prefix:'',
          address: '', 
          phone: '', 
          email: '', 
          contact_full_name: '', 
          contact_phone: '', 
          contact_email: '',
          contact_id:'',
          contact_id_type:"CIN"
        }
    }),
  components: { BreadcrumbComponent, PreloaderComponent },
}
</script>

<style type="text/css">
  .text-right{
    text-align:right;
  }

  .theme--dark.v-btn.v-btn--has-bg{
    background-color:#F68D3A !important;
  }

  .settingscontainer{
    margin-top:10px!important;
  }
  .v-application a{
    color:black!important;
  }
  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  th.text-start, td.text-start{
    text-align:center!important;
  }
  th.text-start:first-child, td.text-start:first-child{
    text-align:left!important;
  }
  th.text-start:last-child, td.text-start:last-child{
    text-align:right!important;
  }
  .class_green{
   background:#4caf50 !important;
   color:white!important;
  }
  .class_red{
   background:#ff5252 !important;
   color:white!important;
  }
  .uppercase{
    text-transform: uppercase;
  }

  .tablesearch .v-input__slot:before{
    display:none;
  }

  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  .edit_button{
    font-size: 16px;
    padding: 5px;
    background: #003f74 !important;
    color: white!important;
    border-radius: 4px;
  }

  .view_button{
    font-size: 16px;
    padding: 5px;
    background: #fb8c00 !important;
    color: white!important;
    border-radius: 4px;
  }
  .v-application .justify-center{
    justify-content: right!important;
  }
  .v-application .v-btn{
    width:70px!important;
    margin-right:22px;
    margin-top:19px;
  }
  .v-dialog .v-card__title{
    background:#f3f3f3;
    border-bottom:1px solid #ddd;
  }
  .v-btn.close_modal_button{
    background-color:#1976d2!important;
    color:white!important;
  }
  .v-btn.confirm_modal_button{
    background-color:#4caf50!important;
    color:white!important;
  }

  .v-dialog .v-card__actions{
    padding-bottom: 15px!important;
    padding-top: 20px!important;
    background: #f3f3f3;
    border-top: 1px solid #ddd;
  }

  .color-red{
    color:red!important;
  }
  .has_errors{
    padding-top:0px;
    margin-top:0px;
  }

  .v-card__actions .v-progress-circular{
    margin-top:1px !important;
  }
    .v-card__actions .v-progress-circular circle{
      color:white!important;
    }
</style>